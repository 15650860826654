import { loadAnimation } from 'lottie-web';
import satisfillmentData from './satisfillment.json';
import satisfillmentModalData from './satisfillment-modal.json';
import univerteData from './univerte.json';
import overteData from './overte.json';

loadAnimation({
  animationData: satisfillmentData,
  autoplay: true,
  container: document.getElementById('satisfillment-graphic'),
  loop: true,
  renderer: 'svg',
});

loadAnimation({
  animationData: satisfillmentModalData,
  autoplay: true,
  container: document.getElementById('satisfillment-modal-graphic'),
  loop: true,
  renderer: 'svg',
});

loadAnimation({
  animationData: univerteData,
  autoplay: true,
  container: document.getElementById('univerte-graphic'),
  loop: true,
  renderer: 'svg',
});

loadAnimation({
  animationData: overteData,
  autoplay: true,
  container: document.getElementById('overte-graphic'),
  loop: true,
  renderer: 'svg',
})